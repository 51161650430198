import React, {useEffect, useRef, useState} from 'react';
import PointerImage from '../assets/images/rotate.png';

function GoodmanCube({instructionText, configData, wrapperClass}) {
    const containerRef = useRef(null);
    const [cg, setCg] = useState(null);
    const [activeItem, setActiveItem] = useState(null)


    useEffect(() => {
        let cg;
        //const container = document.querySelector('.container');
        const GoodmanCube = window.goodmanCube.default;
        setActiveItem(null);
        // console.log();
        // Ensure the DOM node is available
        if (GoodmanCube.isBrowserCompatible()) {
            cg = new GoodmanCube(containerRef.current, configData)
            setCg(cg);
        }

        return () => {
            cg.destroy();
        };

    }, [configData]);

    const handleLink = (text) =>{
        cg.focusSide(text)
        setActiveItem(text)
    }

    return (
        <div className={wrapperClass}>
            <div className="links">
                <span
                    className={`links-link ${activeItem === 'sustainability' ? 'active' : ''}`}
                    onClick={() => handleLink('sustainability')}
                >
                    sustainability
                </span>
                <span
                    className={`links-link ${activeItem === 'innovation' ? 'active' : ''}`}
                    onClick={() => handleLink('innovation')}
                >
                    innovation
                </span>
                <span
                    className={`links-link ${activeItem === 'determination' ? 'active' : ''}`}
                    onClick={() => handleLink('determination')}
                >
                    determination
                </span>
                <span
                    className={`links-link ${activeItem === 'integrity' ? 'active' : ''}`}
                    onClick={() => handleLink('integrity')}
                >
                    integrity
                </span>
            </div>
            <div className="instructions">
                <img src={PointerImage} alt="instruction"/>
                <div>
                    {instructionText}
                </div>
            </div>
            <div id="container" className="container" ref={containerRef}></div>
        </div>
    )
}

export default GoodmanCube;