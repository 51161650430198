export const config_en = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: 'We are building our business for the long-term. That is why we consider people and the planet in what we do. Our actions demonstrate our ongoing commitment to having a positive economic, environmental, and social impact.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: 'New ideas push our business forward. We focus on the future, proactively looking for new opportunities and improved solutions for our stakeholders.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_cn = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '我们立足当下，也放眼未来。因此我们的行动和决策将人与自然和谐共生作为核心考量。我们主动践行我们的承诺——致力为经济、环境和社会带来积极影响。'
            // copyText: '我们立足当下，也放眼未来。因此我们所有行动和决策都会将地球和全人类福祉考虑其中。我们主动践行我们的承诺——致力对全球的经济、环境和社会带来积极影响。'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '坚持创新，方能破局。我们着眼于未来，积极为利益相关者寻找新机遇和优化方案。'
            // copyText: '坚持创新，方能破局。我们着眼于未来，积极为利益相关者寻找新机遇和优化方案，来为彼此构建更好的明天。'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '我们相信，心决者，事竟成。我们坚决追求卓越，并以此激励自己全力以赴，不断为利益相关者产出最佳成果。'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '始终真诚，从未改变。我们用包容、透明的工作方式，在业务与员工、社会与合作伙伴的需求之间寻求最佳平衡。'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_jp = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">サステナビリティ / 持続性</span><br />長期的に持続可能なビジネス。人と地球に配慮し、世界の経済、環境、社会により良い影響をもたらすことが、私たちのコミットメントです。'
            // copyText: '<span style="color:#FFFFFF;">サステナビリティ / 持続性</span><br />持続可能なビジネス。一番大切なのは地球とそこに住む人々です。世界の経済、環境、社会に良い影響 をもたらしたい、そんな姿勢が、現在の取り組みにつながっています'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">イノベーション / 革新性</span><br />革新性がビジネスの原動力。未来志向を持ち、ステークホルダーのために新たな機会や優れたソリューションを模索し続けます。'
            // copyText: '<span style="color:#FFFFFF;">イノベーション / 革新性</span><br />革新性がビジネスの原動力。未来志向を持ち、ステークホルダーのために新たな機会や優れたソリューションを提案し、世界をより良い場所にしていきます。'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">デターミネイション / 実行力</span><br />強い意志で有言実行。目指す最高をかなえる精神で、全力で卓越したサービスを提供します。'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">インテグリティ / 誠実性</span><br />信頼の基本は誠実な行動。インクルージョン（多様性）と透明性を守り、社員、ビジネス、地域社会、そして取引先のニーズのバランスを図ります。'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_fr = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Durabilité</span><br />Nous construisons notre entreprise pour le long terme. Nous considérons les Hommes et la Terre dans toutes nos actions. Nos initiatives témoignent de notre engagement permanent à avoir un impact économique, environnemental et social positif.'
            // copyText: '<span style="color:#FFFFFF;">Durabilité</span><br />Nous bâtissons notre activité sur le long terme. La planète et ses habitants font partie de nos préoccupations quotidiennes. Nos initiatives témoignent de notre engagement permanent à produire un impact économique, environnemental et social positif sur les communautés parmi lesquelles nous opérons.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Innovation</span><br />Les idées novatrices font progresser notre entreprise. Nous nous concentrons sur l\'avenir, recherchant de manière proactive de nouvelles opportunités et des solutions innovantes pour toutes nos parties prenantes.'
            // copyText: '<span style="color:#FFFFFF;">Innovation</span><br />Les nouvelles idées font évoluer notre entreprise. Nous pensons à l\'avenir et recherchons de manière proactive de nouvelles opportunités et des solutions innovantes pour toutes nos parties prenantes, avec l\'objectif constant d\'améliorer le monde dans lequel nous vivions.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Détermination</span><br />La détermination permet d\'accomplir de grandes choses. L\'excellence nous inspire. Nous nous efforçons à l\'atteindre en recherchant le meilleur pour chacun.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Intégrité</span><br />Nous agissons toujours de façon intègre. Nous travaillons en toute transparence et favorions l\'inclusion, en conciliant nos propres besoins avec ceux des communautés et de nos partenaires.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_be_nl = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Duurzaamheid</span><br />Onze bedrijfsvoering richt zich op de lange termijn. Daarom houden we bij alles wat we doen rekening met mens en milieu. Onze acties tonen onze voortdurende inzet voor een positieve impact op de economie, het milieu en de maatschappij.'
            // copyText: '<span style="color:#FFFFFF;">Duurzaamheid</span><br />We richten ons op de lange termijn. Daarom maken initiatieven ter vrijwaring van onze planeet en alles wat erop leeft integraal deel uit van onze dagelijkse bedrijfsvoering. Met deze duurzaamheidsinitiatieven willen we een positieve impact hebben, zowel op economisch, ecologisch als sociaal vlak.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Innovatie</span><br />Nieuwe ideeën stuwen ons bedrijf vooruit. We richten ons op de toekomst en zijn proactief op zoek naar nieuwe opportuniteiten en verbeterde oplossingen voor onze stakeholders.'
            // copyText: '<span style="color:#FFFFFF;">Innovatie</span><br />Nieuwe ideeën stuwen ons bedrijf vooruit. We richten ons op de toekomst en gaan continu en proactief op zoek naar nieuwe opportuniteiten en krachtige oplossingen voor onze stakeholders en de wereld om ons heen.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Vastberadenheid</span><br />Vastberadenheid is onze drijfveer. We willen excelleren in alles wat we doen en werken hard om dat te bereiken. Enkel het beste is goed genoeg voor onze stakeholders.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Integriteit</span><br />Wij zijn integer, in alles wat we doen. Inclusiviteit en transparantie zitten in ons DNA. We streven naar een evenwicht tussen de noden en wensen van ons bedrijf en onze mensen enerzijds, en die van de gemeenschap en onze businesspartners anderzijds.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_it = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Sostenibilità</span><br />Stiamo costruendo il nostro business per un lungo termine. Per questo motivo consideriamo le persone e il pianeta in ciò che facciamo. Le nostre azioni dimostrano il nostro impegno costante per avere un impatto economico, ambientale e sociale positivo.'
            // copyText: '<span style="color:#FFFFFF;">Sostenibilità</span><br />Stiamo costruendo la nostra attività pensando al lungo periodo. Per questo prendiamo in considerazione il pianeta e le persone che lo abitano, in tutto ciò che facciamo. Le nostre iniziative sono la prova del nostro impegno costante ad avere un impatto economico, ambientale e sociale positivo nel mondo.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Innovazione</span><br />Nuove idee spingono il nostro business in avanti. Ci concentriamo sul futuro, cercando in modo proattivo nuove opportunità e soluzioni migliori per i nostri stakeholder.'
            // copyText: '<span style="color:#FFFFFF;">Innovazione</span><br />Sono le nuove idee a mandare avanti il nostro business. Ci concentriamo sul futuro, cercando in modo proattivo nuove opportunità e soluzioni migliori per i nostri stakeholder, così da rendere il mondo un posto migliore per tutti noi.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Determinazione</span><br />È la determinazione a muovere le cose. Siamo motivati dall\'eccellenza e lavoriamo duramente per raggiungerla e ottenere i migliori risultati per i nostri stakeholder.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Integrità</span><br />Agiamo con integrità, sempre. Lavoriamo in modo inclusivo e trasparente, bilanciando le esigenze della nostra azienda e del nostro personale con quelle della comunità e dei nostri interlocutori.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_es = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Sostenibilidad</span><br />Construimos nuestro negocio pensando en el largo plazo. Por eso tenemos en cuenta a las personas y al medio ambiente en todo lo que hacemos. Nuestras acciones demuestran nuestro compromiso permanente de tener un impacto económico, medioambiental y social positivo.'
            //copyText: '<span style="color:#FFFFFF;">Sostenibilidad</span><br />Construimos nuestro negocio para el largo plazo. Por eso tenemos en cuenta al planeta y a todas las personas que lo habitan entodo lo que hacemos. Nuestras iniciativas demuestran nuestro compromiso permanente de tener un impacto económico, medioambiental y social positivo en el mundo.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Innovación</span><br />Las nuevas ideas impulsan nuestro negocio. Nos centramos en el futuro, buscando proactivamente nuevas oportunidades y mejores soluciones para nuestros stakeholders.'
            // copyText: '<span style="color:#FFFFFF;">Innovación</span><br />Las nuevas ideas impulsan nuestro negocio. Nos centramos en el futuro, buscando proactivamente nuevas oportunidades y soluciones mejoradas para nuestros grupos de interés que harán del mundo un lugar mejor para todos nosotros.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Determinación</span><br />La determinación hace que las cosas se hagan. Nos motiva la excelencia y trabajamos duro para conseguirla, buscando activamente los mejores resultados para nuestros stakeholders.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Integridad</span><br />Tenemos integridad, siempre. Trabajamos de forma inclusiva y transparente, equilibrando las necesidades de nuestro negocio y nuestra gente, con las necesidades de la comunidad y de aquellos con los que hacemos negocios.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_de = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Nachhaltigkeit</span><br />Wir richten unser Unternehmen auf die Zukunft aus. Deshalb berücksichtigen wir bei allem, was wir tun, die Menschen und die Erde. Unsere Initiativen zeigen unseren kontinuierlichen Einsatz für einen positiven Einfluss auf Wirtschaft, Umwelt und Gesellschaft.'
            // copyText: '<span style="color:#FFFFFF;">Nachhaltigkeit</span><br />Wir richten unser Unternehmen auf die Zukunft aus. Deshalb stellen wir die Bedürfnisse unserer Erde und der Menschen, die auf ihr leben, in den Mittelpunkt. Unsere Initiativen zeigen unser langfristig angelegtes  Engagement für einen positiven wirtschaftlichen, ökologischen und sozialen Wandel.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Innovation</span><br />Neue Ideen treiben unser Geschäft voran. Wir konzentrieren uns auf die Zukunft und suchen proaktiv nach neuen Chancen und verbesserten Konzepten für unsere Stakeholder.'
            // copyText: '<span style="color:#FFFFFF;">Innovation</span><br />Neue Ideen treiben unser Geschäft voran. Wir zielen auf die Zukunft und suchen aktiv nach neuen Chancen und verbesserten Lösungen für unsere Stakeholder – die Welt zu einem besseren Ort für uns alle zu machen.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Entschlossenheit</span><br />Mit Entschlossenheit gehen wir unsere Aufgaben an. Wir sind hoch motiviert und arbeiten hart daran, die Spitze zu behaupten. Das Beste ist gerade gut genug für unsere Stakeholder.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Integrität</span><br />Wir sind stets integer. Wir arbeiten integrativ und transparent. Wir bringen die Bedürfnisse unseres Unternehmens und unserer Mitarbeiterinnen und Mitarbeiter in Einklang mit den Bedürfnissen der Gemeinschaft und derer, mit denen wir Geschäfte entwicklen.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}

export const config_br = {
    assets: [
        {
            side: 'sustainability',
            path: '/images/sustainability.png',
            copyText: '<span style="color:#FFFFFF;">Sustentabilidade</span><br />Estamos construindo nosso negócio para o longo prazo. É por isso que consideramos as pessoas e o planeta em tudo que fazemos. Nossas ações demonstram nosso compromisso contínuo de causar um impacto econômico, ambiental e social positivo.'
            // copyText: '<span style="color:#FFFFFF;">Sustentabilidade</span><br />Estamos construindo nosso negócio para o longo prazo. É por isso que consideramos o bem-estar do planeta e todas as pessoas que nele vivem em tudo que fazemos. Nossas iniciativas demonstram nosso comprometimento contínuo em ter um impacto economico, social e ambiental positivo no mundo.'
        },
        {
            side: 'innovation',
            path: '/images/innovation.png',
            copyText: '<span style="color:#FFFFFF;">Inovação</span><br />Novas ideias impulsionam o nosso negócio. Nós focamos no futuro, buscando proativamente por novas oportunidades e soluções aprimoradas para nossos stakeholders.'
            // copyText: '<span style="color:#FFFFFF;">Inovação</span><br />Novas ideias movem nosso negócio para frente. Nós focamos no futuro, proativamente procurando novas oportunidades e soluções aprimoradas para nossos investidores que farão do mundo um lugar melhor para todos nós.'
        },
        {
            side: 'determination',
            path: '/images/determination.png',
            copyText: '<span style="color:#FFFFFF;">Determinação</span><br />Determinação é o que faz as coisas ficarem prontas. Estamos motivados pela excelência e trabalhamos duro para atingí-la, ativamente buscando as melhores soluções para nossos investidores.'
        },
        {
            side: 'integrity',
            path: '/images/integrity.png',
            copyText: '<span style="color:#FFFFFF;">Integridade</span><br />Agimos com integridade, sempre. Nós trabalhamos com inclusão e transparência, balanceando as necessidades de nossos negócios e nossas equipes com as necessidades da comunidade e daqueles com os quais fazemos negócios.'
        },
        {
            side: 'top',
            path: '/images/logo.png',
        },
        {
            side: 'bottom',
            path: '/images/logo.png',
        },
    ],
    addPaddingForCopy: true,
}