import './assets/css/style.css'
import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Header from "./components/Header";
import GoodmanCube from "./components/GoodmanCube";
import {config_en, config_cn, config_jp, config_fr, config_be_nl, config_it, config_es, config_de, config_br} from "./utils/config";

const routes = [
    {
         path: '/',
         introText: 'Explore',
         config: config_en,
         lang: 'en',
         wrapperClass: 'en',
    },
    {
        path: '/cn',
        introText: 'Explore',
        config: config_cn,
        lang: 'cn',
        wrapperClass: 'cn',
    },
    {
        path: '/jp',
        introText: 'Explore',
        config: config_jp,
        lang: 'jp',
        wrapperClass: 'jp',
    },
    {
        path: '/be-nl',
        introText: 'Ontdek',
        config: config_be_nl,
        lang: 'be/nl',
        wrapperClass: 'be-nl'
    },
    {
        path: '/fr',
        introText: 'Explorez',
        config: config_fr,
        lang: 'fr',
        wrapperClass: 'fr'
    },
    {
        path: '/it',
        introText: 'Approfondisci',
        config: config_it,
        lang: 'it',
        wrapperClass: 'it'
    },
    {
        path: '/es',
        introText: 'Explore',
        config: config_es,
        lang: 'es',
        wrapperClass: 'es'
    },
    {
        path: '/de',
        introText: 'Forschergeist',
        config: config_de,
        lang: 'de',
        wrapperClass: 'de'
    },
    {
        path: '/br',
        introText: 'Explorar',
        config: config_br,
        lang: 'br',
        wrapperClass: 'br'
    },
];

function App() {

  return (
      <Router>
          <div className="app">
              <Header />
              <div className="country">
                  {routes.map((route) => (
                  <div key={route.path} className="link">
                      <NavLink to={route.path} className={({ isActive }) => isActive ? 'active' : ''}>{route.lang}</NavLink>
                  </div>
                  ))}
              </div>
              <Routes>
                  {routes.map((route) => (
                  <Route
                      key={route.path}
                      path={route.path}
                      element={
                      <GoodmanCube instructionText={route.introText} configData={route.config} wrapperClass={route.wrapperClass} />
                  } />
                  ))}
              </Routes>
          </div>
      </Router>
  );
}

export default App;
